
@import '../../styles/_appwork/functions';

@mixin react-big-calendar-event-variant($parent, $background) {
  $parent: if($parent !=null, '.rbc-event-#{$parent}.rbc-event', '.rbc-event');

  #{$parent} {
    background: rgba($background, .15) !important;

    &.rbc-selected {
      background: rgba-to-hex(rgba($background, .3)) !important;
    }
  }

  .rbc-day-slot #{$parent} {
    border-color: rgba($background, .75) !important;
  }
}

@mixin react-big-calendar-theme($background) {
  @include react-big-calendar-event-variant(null, $background);

  // .cv-day.draghover {
  //   box-shadow: inset 0 0 0.0 .125rem $background !important;
  // }

  .rbc-today {
    background: rgba($background, .05) !important;
  }
}
